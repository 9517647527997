export const MobileCarouselData = [
    {
        image: "/images/homepage_images/home-slider/mobile-slider-1.png",
        path: "/women/clothing/lengha_choli"
    },
    {
        image: "/images/homepage_images/home-slider/mobile-slider-2.png",
        path: "/women/clothing/lengha_choli"
    },
    
]