import React from 'react'
import OurProducts from '../pages/HomepageComponents/OurProducts'
// import './ProductLive.css'

const ProductLive = () => {
  return (
    <div className='container-products'>
      <OurProducts/>
    </div>
  )
}

export default ProductLive
