export const NewCarouselData = [
    {
        image: "/images/homepage_images/home-slider/slider-4.png",
        path: "/women/clothing/lengha_choli"
    },
    {
        image: "/images/homepage_images/home-slider/slider-5.png",
        path: "/women/clothing/lengha_choli"
    },
    
]